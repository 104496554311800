export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      // backgroundColor: 'transparent',
      color: 'white',
      position: 'fixed',
      background: 'linear-gradient(rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 50%, rgba(0, 0, 0, 0) 100%)',
      // borderBottom: '1px solid lightgrey',
      padding: ['0.5rem', '0.5rem', '1rem', '1rem'],
      '.navItem a:hover': {
        color: 'primary'
      }
    },
    '.containerScrolled': {
      background: 'unset',
      backgroundColor: 'white',
      color: 'black',
      borderBottom: '1px solid lightgrey',
      '.navItem a:hover': {
        color: 'primary'
      }
    },
    '.smallNavMenu > div': {
      borderColor: 'black',
      border: 'none'
    },
    '.navItem': {
      // color: 'white',
    },
    '.hamburger > div': {
      backgroundColor: 'primary'
    },
    '.navMenuOpen': {
      backgroundColor: 'backgroundSecondary',
      '.phoneSocialContainer': {
        '.socialIconsContainer': {
          display: 'none!important'
        }
      }
    },
    '.socialIconsContainer, .phoneContainer': {
      color: 'black',
      borderColor: 'black',
      svg: {
        path: {
          fill: 'black'
        }
      }
    },
    '.logo': {
      img: {
        maxHeight: ['65px', '65px'],
        filter: 'brightness(0) invert(1)'
      }
    },
    '.logoScrolled': {
      maxHeight: ['50px', '50px'],
      img: {
        maxHeight: ['50px', '50px'],
        filter: 'unset'
      }
    }
  },

  footer: {
    borderTop: '1px solid lightgrey',
    backgroundColor: 'backgroundSecondary',
    maxWidth: 'unset',
    '.logo': {
      maxHeight: '80px'
    },
    '.socialContainer, .contactDetails-container': {
      svg: {
        path: {
          fill: 'grey'
        }
      }
    },

    '.poweredBy .gonationLogo': {
      filter: 'brightness(0) invert(0.6)'
    },

    '.multiButtonContainer a': {
      variant: 'buttons.secondary',
      borderRadius: '0px'
    }
  },

  ctaWidget: {
    display: 'none',
    a: {
      backgroundColor: 'secondary'
    }
  },

  // ? ====================
  // ? ===  reuseables  ===
  // ? ====================

  title: {
    color: 'primary',
    fontSize: ['2rem', '2.5rem', '3rem', '4rem'],
    order: '2'
  },
  subtitle: {
    color: 'secondary',
    borderBottom: '2px solid',
    borderColor: 'secondary',
    width: 'fit-content',
    order: '1'
  },
  text: {
    color: 'text',
    order: '3'
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.slick-arrow': {
      display: 'none !important'
    },
    '.title': {
      fontSize: ['2.2rem', '', '2.5rem'],
      color: 'secondary',
      textTransform: 'uppercase',
      letterSpacing: '1px'
    },
    color: 'light',
    '.shoutWidget': {
      display: 'none'
    },
    '.slick-dots li.slick-active button:before': {
      color: 'secondary'
    },
    '.slick-dots li button:before': {
      fontSize: '15px'
    },

    '.hero_content_container': {
      display: 'none',
      // marginBottom: ['', '', '', '7rem'],
      padding: ['2rem', '', '', '4.5rem', '5.5rem'],

      '.title': {
        variant: 'customVariants.title',
        order: '2',
        color: 'white'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        order: '1'
      },

      '.text': {
        variant: 'customVariants.text',
        order: '3',
        color: 'white'
      },
      a: {
        order: '4',
        alignSelf: 'unset',
        marginTop: '1rem',
        '.ctaButton': {
          variant: 'buttons.primary'
        }
      }
    }
  },

  homepageHeroShout: {
    // display: 'none',
    position: 'absolute',
    bottom: ['0rem', '', '1rem'],
    left: '0rem',
    '.shoutWidget': {
      backgroundColor: ['#f0f0e6', '', 'rgb(240 240 230 / 66%)'],
      maxHeight: '200px',
      maxWidth: ['unset', '', '', '40%'],
      '.imageContainer img': {
        border: 'none'
      },
      '.title': {
        fontSize: '1rem',
        fontFamily: 'heading',
        textDecoration: 'underline'
      },
      '.text': {
        fontSize: '1.5rem',
        color: 'primary'
      }
    }
  },

  homepageAboutSection: {
    margin: '2rem 0rem',
    '.content': {
      width: ['', '', '45%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      backgroundColor: 'backgroundSecondary',
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        order: '3'
      },
      a: {
        order: '4'
      }
    },
    '.lazyload-wrapper': {
      width: ['', '', '55%'],
      '::before': {
        background: 'unset'
      },
      img: {
        height: '65vh'
      }
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  homepageMenuSection: {
    margin: '2rem 0rem',
    '.content': {
      width: ['', '', '45%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      backgroundColor: 'backgroundSecondary',
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        order: '3'
      },
      a: {
        order: '4',
        '.ctaButton': {
          variant: 'buttons.secondary'
        }
      }
    },
    '.lazyload-wrapper': {
      order: '2',
      width: ['', '', '55%'],
      '::before': {
        background: 'unset'
      },
      img: {
        height: '65vh'
      }
    }
  },

  homepageGallerySection: {
    variant: 'customVariants.homepageAboutSection'
  },
  homepageEventsSection: {
    variant: 'customVariants.homepageMenuSection'
  },

  homepageSlider: {
    padding: '0rem',
    '.slick-dots': {
      display: 'none !important'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {
    maxWidth: 'unset',
    '.menuContainer': {
      paddingBottom: '1rem'
    },
    '.menuSectionTitle': {
      fontSize: ['2rem', '2.5rem', '3rem'],
      color: 'secondary'
    },
    '.allInContainerWrapper': {},
    '.menuItemInnerContainer': {},
    '.menuItemImageContainer': {},
    '.menuItemName': {
      color: 'black'
    },
    '.menuItemDescription': {
      color: 'primary'
    },
    '.menuItemContentContainer': {
      margin: '0rem'
    },
    '.dropdownContainer': {
      display: 'none'
    },
    '.section-cell': {
      backgroundColor: 'backgroundSecondary',
      borderRadius: '0px',
      ':hover': {
        '.cellImage': {}
      }
    },
    '.cellImageContainer': {
      borderRadius: '0px',
      '.imageFill': {},
      img: {
        borderRadius: '0px'
      }
    },
    '.menuCell, .cellImage': {},
    '.menuCell': {
      backgroundColor: 'backgroundSecondary',
      borderRadius: '0px'
    },
    '.cellName': {
      color: 'primary',
      borderTop: '2px solid',
      borderBottom: '2px solid',

      borderColor: 'secondary'
    },
    '.menuItemContainerImgActive': {},
    '.backToMenuBtn': {
      variant: 'buttons.primary'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    // '.albumsCTA ': {
    //   variant: 'buttons.secondary',
    // },
    '.albumsContainer': {
      display: 'none'
    },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black'
    }
  },

  locationMap: {
    order: '3',
    h3: {
      textTransform: 'uppercase'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '4rem 1rem',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },
      '.submitBtn': {
        variant: 'buttons.primary'
        // borderRadius: '0px',
      }
    }
  },

  locationMap: {
    h3: {
      fontSize: '2rem',
      color: 'primary',
      textDecoration: 'underline'
    },
    iframe: {
      display: 'none'
    },
    '.content_container': {
      flexGrow: '1',
      backgroundColor: 'backgroundSecondary'
    }
  }
}
